import { AgentSearch, AgentSearchProps } from 'components/agent-search';
import { getThemeOverrideFromRequest } from 'utils/themes';
import HeadData from 'components/head-data';
import { ThemeNames as TenantNames } from 'types/themes';
import Layout from 'components/layout';
import { v4 as uuidv4 } from 'uuid';

import type { GetServerSidePropsContext } from 'next';
import { themes as tenants } from 'themes';
import { AgentType } from 'utils/agent-endpoint';
import { getTenantById } from 'tenants';

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const sessionId = uuidv4();

  const tenantName = getThemeOverrideFromRequest(context.req);
  const tenant = tenants[tenantName];

  let title = 'Experts - eXp Commercial Brokerage';
  let metaDescription = '';

  if (tenant) {
    title = `Find a Local Real Estate Agent ${tenant.title}`;
    metaDescription = tenant.metaDescription;
  }

  if (tenantName !== TenantNames.EXP_COMMERCIAL) {
    return {
      redirect: {
        permanent: true,
        destination: '/404',
      },
    };
  }

  const agentSearchConfig = getTenantById(tenantName)?.config.agentSearch;

  return {
    props: {
      agentType: AgentType.COMMERCIAL,
      initialCountry: 'US',
      sessionId,
      tenantName,
      config: agentSearchConfig ?? null,
      headerData: {
        title,
        metaDescription,
      },
    },
  };
}

export default function AgentsPage(props: AgentSearchProps) {
  return (
    <Layout>
      <HeadData data={props.headerData} />
      <AgentSearch {...props} />
    </Layout>
  );
}